import type { WidgetType, WidgetParams, WidgetStyles } from '@/components/ui/WidgetSettings/definition'

export class VueGridItem {
  x: number;
  y: number;
  w: number;
  h: number;
  i: number;
  type?: WidgetType;
  title: string;
  params?: WidgetParams;
  styles?: WidgetStyles;
}

export class ChartDataset {
  datasetAirTemp: boolean;
  datasetTrackTemp: boolean;
  datasetWindAvg: boolean;
  datasetWindGust: boolean;
  datasetHumidity: boolean;
  datasetPressure: boolean;
  datasetRainIntensity: boolean;
  datasetWindDirection: boolean;
  datasetCarWindDirection: boolean;
  datasetWBGT: boolean;
  datasetHeatIndex: boolean;
  datasetLightIntensity: boolean;
}

export type DatasetScale = 'temperature' | 'wind' | 'humidity' | 'pressure' | 'rain' | 'lightIntensity'

export const datasetsConfiguration: Record<string, {
  datasetKey: string;
  datasetScale: DatasetScale;
  datasetBindings: {
    index: number;
    rangeMode?: string;
    rangeMin?: number;
    rangeMax?: number | Function;
    rangeMin2?: number;
    rangeMax2?: number;
    displayMin?: boolean;
    displayAvg?: boolean;
    displayMax?: boolean;
}[];
}> = {
  datasetAirTemp: {
    datasetKey: 'datasetTemperature',
    datasetScale: 'temperature',
    datasetBindings: [{
      index: 0
    }, {
      index: 4
    }]
  },
  datasetTrackTemp: {
    datasetKey: 'datasetTemperature',
    datasetScale: 'temperature',
    datasetBindings: [{
      index: 1
    }, {
      index: 5
    }]
  },
  datasetWBGT: {
    datasetKey: 'datasetTemperature',
    datasetScale: 'temperature',
    datasetBindings: [{
      index: 2
    }, {
      index: 6
    }]
  },
  datasetHeatIndex: {
    datasetKey: 'datasetTemperature',
    datasetScale: 'temperature',
    datasetBindings: [{
      index: 3
    }, {
      index: 7
    }]
  },
  datasetWindAvg: {
    datasetKey: 'datasetWind',
    datasetScale: 'wind',
    datasetBindings: [{
      index: 0
    }, {
      index: 2
    }]
  },
  datasetWindGust: {
    datasetKey: 'datasetWind',
    datasetScale: 'wind',
    datasetBindings: [{
      index: 1
    }, {
      index: 3
    }]
  },
  datasetHumidity: {
    datasetKey: 'datasetHuPressure',
    datasetScale: 'humidity',
    datasetBindings: [{
      index: 0,
      rangeMode: 'tozero'
    }]
  },
  datasetPressure: {
    datasetKey: 'datasetHuPressure',
    datasetScale: 'pressure',
    datasetBindings: [{
      index: 1,
      rangeMode: 'tozero'
    }]
  },
  datasetRainIntensity: {
    datasetKey: 'datasetRain',
    datasetScale: 'rain',
    datasetBindings: [{
      index: 0,
      rangeMin: 0,
      rangeMax: (dataset) => dataset.maxHumidity + dataset.humidityAddition,
      rangeMode: 'tozero'
    }]
  },
  datasetWindDirection: {
    datasetKey: 'datasetWindRoseDirection',
    datasetScale: 'wind',
    datasetBindings: [{
      index: 0,
      rangeMode: 'tozero',
      displayMin: false,
      displayAvg: false,
      displayMax: false
    }]
  },
  datasetCarWindDirection: {
    datasetKey: 'datasetWindRoseCarDirection',
    datasetScale: 'wind',
    datasetBindings: [{
      index: 0,
      rangeMode: 'tozero',
      displayMin: false,
      displayAvg: false,
      displayMax: false
    }]
  },
  datasetLightIntensity: {
    datasetKey: 'datasetLightIntensity',
    datasetScale: 'lightIntensity',
    datasetBindings: [{
      index: 0,
      rangeMode: 'tozero'
    }, {
      index: 1
    }]
  }
}
