

































































































































































import Vue from 'vue'
import ToggleSwitch from '@/components/ui/ToggleSwitch/ToggleSwitch.vue'
import Button from '@/components/ui/Button/Button.vue'
import Dialog from '@/components/ui/Dialog/Dialog.vue'
import VueSlider from 'vue-slider-component'

export default Vue.extend({
  name: 'map-settings',
  components: {
    'ui-toggle-switch': ToggleSwitch,
    'ui-button': Button,
    'ui-dialog': Dialog,
    VueSlider
  },
  props: {
    isLayerRainpathDisplayed: Boolean,
    isLayerWeatherStationsDisplayed: Boolean,
    displayMapCenter: {
      type: Boolean,
      required: false,
      default: true
    },
    osmOpacity: {
      type: Number,
      required: false,
      default: 1
    },
    radarOpacity: {
      type: Number,
      required: false,
      default: 1
    },
    airParifOpacity: {
      type: Number,
      required: false,
      default: 1
    },
    isLayerWindArrowsDisplayed: {
      type: Boolean,
      default: false
    },
    radarPeriods: {
      type: Array,
      required: false,
      default: () => ([])
    },
    airParifLayerAllowed: {
      type: Boolean,
      default: false
    },
    dataLayer: {
      type: String,
      default: 'radar'
    },
    mapBackground: {
      type: String,
      default: 'light'
    }
  }
})
