/* eslint-disable no-case-declarations */
import { WidgetParams, WidgetStyles, WidgetType } from '@/components/ui/WidgetSettings/definition'
import { DatasetScale } from '@/views/CustomDashboard/definition'
import { selectionSessions } from '../ClimateRecap/helper'

export function setChartDatasetRanges (
  chartDataset,
  dataset,
  scale: DatasetScale,
  rangeMinKey = 'rangeMin',
  rangeMaxKey = 'rangeMax',
  rangeModeKey = 'rangeMode'
) {
  const minMaxAddition = {
    rainIntensityTotal: 0,
    pressureAddition: 5,
    windAddition: 5,
    temperatureAddition: 3,
    humidityAddition: 1
  }
  const currentMin = chartDataset[rangeMinKey]
  const currentMax = chartDataset[rangeMaxKey]
  let newMin = dataset.min
  let newMax = dataset.max
  switch (scale) {
    case 'temperature':
      newMin -= minMaxAddition.temperatureAddition
      newMax += minMaxAddition.temperatureAddition
      chartDataset[rangeMinKey] = currentMin !== null ? Math.min(newMin, currentMin) : newMin
      chartDataset[rangeMaxKey] = currentMax !== null ? Math.max(newMax, currentMax) : newMax
      chartDataset[rangeModeKey] = 'normal'
      break
    case 'humidity':
      newMin -= minMaxAddition.humidityAddition
      newMax += minMaxAddition.humidityAddition
      chartDataset[rangeMinKey] = Math.max(currentMin !== null ? Math.min(newMin, currentMin) : newMin, 0)
      chartDataset[rangeMaxKey] = currentMax !== null ? Math.max(newMax, currentMax) : newMax
      chartDataset[rangeModeKey] = 'tozero'
      break
    case 'pressure':
      newMin -= minMaxAddition.pressureAddition
      newMax += minMaxAddition.pressureAddition
      chartDataset[rangeMinKey] = Math.max(currentMin !== null ? Math.min(newMin, currentMin) : newMin, 0)
      chartDataset[rangeMaxKey] = currentMax !== null ? Math.max(newMax, currentMax) : newMax
      chartDataset[rangeModeKey] = 'normal'
      break
    case 'rain':
      chartDataset[rangeMinKey] = 0
      chartDataset[rangeMaxKey] = Math.max(dataset.max, currentMax)
      chartDataset[rangeModeKey] = 'tozero'
      break
    case 'wind':
      newMin -= minMaxAddition.windAddition
      newMax += minMaxAddition.windAddition
      chartDataset[rangeMinKey] = Math.max(currentMin !== null ? Math.min(newMin, currentMin) : newMin, 0)
      chartDataset[rangeMaxKey] = currentMax !== null ? Math.max(newMax, currentMax) : newMax
      chartDataset[rangeModeKey] = 'tozero'
      break
  }
}

/**
 * Compute default widget parameters
 * and combine them to previously ones
 * according to the widget type
 */
export function getWidgetParams (type: WidgetType, currentWidgetParams: WidgetParams, isSameType = false): WidgetParams {
  let newParams = {}
  switch (type) {
    case 'player':
      if (isSameType && currentWidgetParams.syncedWithPlayer !== undefined) return currentWidgetParams
      newParams = {
        syncedWithPlayer: false
      }
      break
    case 'event-overview':
      if (isSameType && currentWidgetParams.seriesOverview !== undefined) return currentWidgetParams
      newParams = {
        seriesOverview: ['avgAirTemp', 'avgTrackTemp']
      }
      break
    case 'compare-sessions':
      if (isSameType && currentWidgetParams.seriesCompare !== undefined) return currentWidgetParams
      newParams = {
        seriesCompare: ['avgAirTemp', 'avgTrackTemp'],
        ...selectionSessions({
          defaultSessionA: null,
          defaultSessionB: null,
          defaultStationA: 'all',
          defaultStationB: 'all'
        }, this.stateEvent.sessionsWithStat)
      }
      break
    case 'weather-radar-map':
      if (isSameType && currentWidgetParams.osmOpacity !== undefined) return currentWidgetParams
      newParams = {
        osmOpacity: 1,
        radarOpacity: 1,
        displayLayersWeatherStations: true,
        displayLayersWindArrows: true,
        displayLayersRainpath: true,
        syncedWithPlayer: true
      }
      break
    case 'weather-station-listing':
      if (isSameType && currentWidgetParams.currentStation !== undefined) return currentWidgetParams
      newParams = {
        currentStation: 0,
        syncedWithPlayer: false
      }
      break
    case 'weather-station-listing-multi-station':
      if (isSameType && currentWidgetParams.stationSet !== undefined) return currentWidgetParams
      newParams = {
        stationSet: ['station1'],
        syncedWithPlayer: false
      }
      break
    case 'ground-station-chart':
      if (isSameType && currentWidgetParams.stationDataset !== undefined) return currentWidgetParams
      newParams = {
        stationIndex: 0,
        stationDataset: {
          datasetAirTemp: true,
          datasetTrackTemp: true,
          datasetWindAvg: false,
          datasetWindGust: false,
          datasetHumidity: false,
          datasetPressure: false,
          datasetRainIntensity: false,
          datasetWindDirection: false,
          datasetCarWindDirection: false,
          datasetWBGT: false,
          datasetHeatIndex: false,
          datasetLightIntensity: false
        },
        displaySession: true,
        syncedWithPlayer: false
      }
      break
    case 'ground-station-chart-multi-station':
      if (isSameType && currentWidgetParams.stationDataset !== undefined) return currentWidgetParams
      newParams = {
        stationSet: ['station1', 'station2', 'station3', 'station4'],
        stationDataset: {
          datasetAirTemp: true,
          datasetTrackTemp: false,
          datasetWindAvg: false,
          datasetWindGust: false,
          datasetHumidity: false,
          datasetPressure: false,
          datasetRainIntensity: false,
          datasetLightIntensity: false
        },
        displaySession: true,
        syncedWithPlayer: false
      }
      break
    case 'forecast':
      if (isSameType && currentWidgetParams.temperatureOverLimit !== undefined) return currentWidgetParams
      newParams = {
        temperatureOverLimit: this.stateConfig.data.LIMIT_FORECAST_HOURLY_TEMPERATURE_AIR,
        chanceOfRainOverLimit: this.stateConfig.data.LIMIT_FORECAST_HOURLY_RAIN_CHANCE || [],
        windOverLimit: this.stateConfig.data.LIMIT_FORECAST_BYDAY_WIND || [],
        gustsOverLimit: this.stateConfig.data.LIMIT_FORECAST_HOURLY_WIND_GUSTS || [],
        humidityOverLimit: this.stateConfig.data.LIMIT_FORECAST_HOURLY_HUMIDITY,
        pressureOverLimit: this.stateConfig.data.LIMIT_FORECAST_HOURLY_PRESSURE,
        trackMinOverLimit: this.stateConfig.data.LIMIT_FORECAST_HOURLY_TEMPERATURE_TRACK_MIN,
        trackMaxOverLimit: this.stateConfig.data.LIMIT_FORECAST_HOURLY_TEMPERATURE_TRACK_MAX
      }
      break
    default:
      if (isSameType && currentWidgetParams.syncedWithPlayer !== undefined) return currentWidgetParams
      newParams = {
        syncedWithPlayer: false
      }
  }
  return newParams
}

export function getWidgetStyles (type: WidgetType, currentWidgetStyles: WidgetStyles): WidgetStyles {
  const newStyles = {
    padding: currentWidgetStyles.padding ?? true,
    border: currentWidgetStyles.border ?? true,
    displayTitle: currentWidgetStyles.displayTitle ?? true,
    opacity: currentWidgetStyles.opacity ?? 100,
    size: currentWidgetStyles.size ?? 'base'
  }
  switch (type) {
    case 'player':
    case 'weather-radar-map':
      newStyles.displayTitle = false
  }

  return newStyles
}
